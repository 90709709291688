<template>
  <div class="page-container">
    <div class="school-info">
      <div class="name">{{ userInfo.schoolname }} 思政课虚拟仿真实验统计信息</div>
      <div class="total-info">
        <div class="flex flex-start">
          <span>总用户数：</span>
          <span>3703 人</span>
        </div>
        <div class="flex flex-start">
          <span>总实验次数：</span>
          <span>10513 人次</span>
        </div>
        <div class="flex flex-start">
          <span>平均分：</span>
          <span>96 分</span>
        </div>
      </div>
    </div>
    <div class="experiment-info">
      <div class="flex flex-start flex-wrap experiment-list">
        <el-card class="box-card li">
          <div class="flex flex-dr flex-center">
            <img src="https://resouce.cdzyhd.com/a5105425-e234-422b-8d95-a69b7c761b5f.png" alt="">
            <span class="name">飞夺泸定桥</span>
            <span>总实验次数：1050 人次</span>
            <span>平均分：93分</span>
            <span class="new">最新实验记录</span>
            <el-table :data="list[0]">
              <el-table-column label="账号" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.date }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
        <el-card class="box-card li">
          <div class="flex flex-dr flex-center">
            <img src="https://resouce.cdzyhd.com/b94993a7-4dd4-4f67-8dd6-4adc85fbdebc.png" alt="">
            <span class="name">四渡赤水</span>
            <span>总实验次数：1140 人次</span>
            <span>平均分：97分</span>
            <span class="new">最新实验记录</span>
            <el-table :data="list[1]">
              <el-table-column label="账号" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.date }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
        <el-card class="box-card li">
          <div class="flex flex-dr flex-center">
            <img src="https://resouce.cdzyhd.com/e7f1ebbd-9c6d-4d65-a409-99bf0a85c2c6.png" alt="">
            <span class="name">彝海结盟</span>
            <span>总实验次数：1203 人次</span>
            <span>平均分：98分</span>
            <span class="new">最新实验记录</span>
            <el-table :data="list[2]">
              <el-table-column label="账号" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.date }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
        <el-card class="box-card li">
          <div class="flex flex-dr flex-center">
            <img src="https://resouce.cdzyhd.com/6e4aac63-9d3b-467d-8d88-bb41c301501c.png" alt="">
            <span class="name">爬雪山过草地</span>
            <span>总实验次数：1320 人次</span>
            <span>平均分：98分</span>
            <span class="new">最新实验记录</span>
            <el-table :data="list[3]">
              <el-table-column label="账号" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.date }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
        <el-card class="box-card li">
          <div class="flex flex-dr flex-center">
            <img src="https://resouce.cdzyhd.com/465da485-d30e-47ae-8440-0048dde5a860.jpg" alt="">
            <span class="name">红色掌柜</span>
            <span>总实验次数：1905 人次</span>
            <span>平均分：97分</span>
            <span class="new">最新实验记录</span>
            <el-table :data="list[4]">
              <el-table-column label="账号" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.date }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
        <el-card class="box-card li">
          <div class="flex flex-dr flex-center">
            <img src="https://resouce.cdzyhd.com/61eda5c8-4934-4117-82f7-63d43c2b1af1.jpg" alt="">
            <span class="name">新华日报</span>
            <span>总实验次数：1930 人次</span>
            <span>平均分：97分</span>
            <span class="new">最新实验记录</span>
            <el-table :data="list[5]">
              <el-table-column label="账号" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.date }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
        <el-card class="box-card li">
          <div class="flex flex-dr flex-center">
            <img src="https://resouce.cdzyhd.com/5e87f8ea-722d-4dbd-87b7-08f662e172e1.png" alt="">
            <span class="name">统一战线</span>
            <span>总实验次数：1920 人次</span>
            <span>平均分：96分</span>
            <span class="new">最新实验记录</span>
            <el-table :data="list[6]">
              <el-table-column label="账号" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.account }}</span>
                </template>
              </el-table-column>
              <el-table-column label="姓名" align="center" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" width="50">
                <template slot-scope="scope">
                  <span>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.date }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import ListSearchFilter from '@/components/list/listSearchFilter'
import {EnumsModel} from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {date_format, find_obj_from_arr_by_id, objectToLVArr} from '@/utils/common'
import {mapState} from 'vuex'
import {StudentModel} from "@/model/exp/StudentModel";
import {dateFormat, numberFormat} from "@/filter/filter";
import {ScoreModel} from "@/model/exp/ScoreModel";
import {ClazzModel} from "@/model/exp/ClazzModel";
import {CommonModel} from "@/model/CommonModel";

export default {
  name: 'teacherScoreStatistic',
  components: {ListSearchFilter},
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    dateFormat: dateFormat,
    numberFormat: numberFormat
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      list: [
        [
          {account: "202116123", name: "李怡静", score: "80", date: "2021-06-28 16:32:09"},
          {account: "202116147", name: "潘梓豪", score: "96", date: "2021-06-27 23:15:25"},
          {account: "202712135", name: "龚永强", score: "100", date: "2021-06-22 13:12:14"},
          {account: "202208244", name: "胡海银", score: "96", date: "2021-06-22 12:39:23"},
          {account: "202507243", name: "李飞洋", score: "92", date: "2021-06-21 23:34:51"}
        ],
        [
          {account: "202513326", name: "杜畅", score: "100", date: "2021-06-15 00:22:41"},
          {account: "202511215", name: "高林思", score: "88", date: "2021-06-14 18:23:10"},
          {account: "202210745", name: "赵旅", score: "100", date: "2021-06-12 22:19:46"},
          {account: "202211341", name: "林逸", score: "100", date: "2021-06-12 20:55:29"},
          {account: "202208340", name: "张译丹", score: "100", date: "2021-06-09 11:40:13"}
        ],
        [
          {account: "202513326", name: "杜畅", score: "100", date: "2021-06-15 01:16:16"},
          {account: "202513147", name: "金武林", score: "100", date: "2021-06-12 01:57:59"},
          {account: "202712601", name: "陈美霞", score: "100", date: "2021-06-05 23:25:51"},
          {account: "202712601", name: "陈美霞", score: "100", date: "2021-06-05 23:00:24"},
          {account: "202120212", name: "何静", score: "100", date: "2021-06-01 20:44:08"}
        ],
        [
          {account: "202507307", name: "李磊", score: "100", date: "2021-06-14 21:15:39"},
          {account: "202309102", name: "冉帆", score: "100", date: "2021-06-14 21:13:08"},
          {account: "202513145", name: "刘江", score: "100", date: "2021-06-14 20:40:06"},
          {account: "202513254", name: "李俊辉", score: "100", date: "2021-06-14 20:09:29"},
          {account: "202711334", name: "陈谭原", score: "100", date: "2021-06-13 19:03:11"},
        ],
        [
          {account: "202118618", name: "马秋燕", score: "100", date: "2021-06-27 10:43:12"},
          {account: "202116223", name: "宋利甜", score: "100", date: "2021-06-24 19:37:26"},
          {account: "202202101", name: "苏琪", score: "100", date: "2021-06-22 13:59:31"},
          {account: "202507323", name: "蒲雲涛", score: "100", date: "2021-06-21 21:51:09"},
          {account: "202311203", name: "肖文钦", score: "100", date: "2021-06-17 22:15:11"},
        ],
        [
          {account: "202701210", name: "刘诗琳", score: "100", date: "2021-06-06 00:07:33"},
          {account: "202711235", name: "周莉", score: "100", date: "2021-06-02 19:43:09"},
          {account: "202711440", name: "罗梅", score: "100", date: "2021-05-29 23:15:24"},
          {account: "202711424", name: "吴俣", score: "100", date: "2021-05-29 23:03:45"},
          {account: "202711419", name: "赵旭", score: "100", date: "2021-05-29 21:16:19"},
        ],
        [
          {account: "202511248", name: "何金松", score: "100", date: "2021-06-30 10:45:13"},
          {account: "202511240", name: "母堯丹", score: "100", date: "2021-06-16 16:01:38"},
          {account: "202720148", name: "谭钰潇", score: "100", date: "2021-06-15 23:53:32"},
          {account: "202720150", name: "吴磊", score: "100", date: "2021-06-15 23:43:44"},
          {account: "202720147", name: "朱豪", score: "100", date: "2021-06-15 23:36:18"},
        ]
      ]
    }
  },
  async mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="less">
.school-info {
  margin-bottom: 30px;

  .name {
    font-size: 20px;
    text-align: center;
  }

  .total-info {
    > div {
      margin-top: 20px;

      > span:nth-child(1) {
        color: #333;
      }

      > span:nth-child(2) {
        color: #777;
      }
    }
  }
}

.experiment-info {
  .experiment-list {
    .li {
      margin-right: 20px;
      width: 500px;
      margin-bottom: 30px;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }

    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #666;
    }

    .new {
      margin-top: 20px;
    }

    .name {
      font-size: 17px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
